
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent, IonFab, IonFabButton, IonGrid, IonIcon,
  IonImg,
  IonPage,
  IonRow
} from '@ionic/vue';
import { defineComponent } from 'vue';
import {mapGetters} from "vuex";
import {addOutline} from "ionicons/icons";

export default defineComponent({
  name: 'AdminInvitationsDesigns',
  components: {
    IonFab,
    IonIcon,
    IonFabButton,
    IonContent,
    IonPage,
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonImg,
    IonGrid
  },
  computed: {
    ...mapGetters(['invitationDesigns'])
  },
  data(): any {
    return {
      icons: {
        addOutline
      }
    }
  },
});
